import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, baseStyles } from './Button'

const FormWrapper = styled.div`
  margin:0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
  margin: 0 0 ${props => props.theme.size.sectionSpacing} 0;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const Form = styled.form`
  width: 20em;
  margin:0;
`

const FormTextArea = styled.textarea`
  height: auto;
  width: 100%;
`

const ModalOverlay = styled.div`
  top:0;
  left:0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 1000;
  background-color: rgba(0,0,0,0.3);
  opacity: ${props => (props.visible ? '1' : '0')};
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
`

const Modal = styled.div`
  background: ghostwhite;
  padding: 2em;
  position: fixed;
  min-width: 75%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  z-index: 2000;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  transition: 0.2s all;
  opacity: ${props => (props.visible ? '1' : '0')};
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  p {
    line-height: 1.6;
    margin: 0 0 2em 0;
  }
`

const Submit = styled.input`
  ${baseStyles};
`

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

class ConfessionForm extends React.Component {
  constructor(props) {
		super(props)
		this.state = {
      active: false,
      confession: '',
      showModal: false,
      submitting: false
    }
	}

	open = () => (
    this.setState({active: true})
  )

  close = () => (
    this.setState({active: false})
  )

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  handleSubmit = event => {
    this.setState({
      submitting: true
    })
    fetch('/.netlify/functions/confession', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: encode({ confession: this.state.confession })
    })
      .then(this.handleSuccess)
      .catch(error => alert(error))
    event.preventDefault()
  }

  handleSuccess = () => {
    this.setState({
      active: false,
      confession: '',
      showModal: true,
      submitting: false
    })
  }

  closeModal = () => {
    this.setState({ showModal: false })
  }

  render() {
    return (
      <FormWrapper>
        <Button important
          onClick={this.open.bind(this)}
          style={{display: this.state.active ? 'none' : 'block'}}
        >
          Submit yours
        </Button>
        <Form
          style={{display: this.state.active ? 'block' : 'none'}}
          onSubmit={this.handleSubmit}
          overlay={this.state.showModal}
          onClick={this.closeModal}
        >
          <FormTextArea
            id="confession"
            name="confession"
            title="Confession"
            placeholder="I work from home. My boss doesn't know that I had a baby 🤷‍"
            required
            minlength="10"
            value={this.state.confession}
            onChange={this.handleInputChange}
          />
          <input type="hidden" name="form-name" value="tech-confession" />
          <br />
          <ButtonWrapper>
            <Submit name="submit" type="submit" value={this.state.submitting ? "Submitting..." : "Confess"} disabled={this.state.submitting} important />
          </ButtonWrapper>
        </Form>
        <ModalOverlay onClick={this.closeModal} visible={this.state.showModal} />
        <Modal visible={this.state.showModal}>
          <p>
            Thanks for your confession! We are reviewing it and may post it for others to see.
          </p>
          <Button onClick={this.closeModal}>Okay</Button>
        </Modal>
      </FormWrapper>
    )
  }
}

ConfessionForm.propTypes = {
  data: PropTypes.object,
}

export default ConfessionForm
