import React from 'react'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import { Button } from '../components/Button'
import Link from '../components/Link'
import PaginationLinks from '../components/PaginationLinks'
import Confession from '../components/Confession'
import Heading from '../components/Heading'
import ConfessionForm from '../components/ConfessionForm'
import Layout from '../components/Layout'

const ConfessionsTemplate = ({ data, pageContext }) => {
  const { group, index, pageCount } = pageContext;
  const previousUrl = index - 1 == 1 ? "/" : "/" + (index - 1).toString() + "/" ;
  const nextUrl = "/" + (index + 1).toString() + "/" ;
  const indexSlug = index === 1 ? "" : index.toString() + "/"

  return (
    <Layout>
      <Helmet>
        <title>{`Mom Confessions | ${config.siteTitle}`}</title>
        <meta
          property="og:title"
          content={`Mom Confessions - ${config.siteTitle}`}
        />
        <meta property="og:url" content={`${config.siteUrl}/confessions/${indexSlug}`} />
      </Helmet>
      <Heading.H1 handwriting xlarge tac>Mom Confessions</Heading.H1>
      <ConfessionForm />
      {group.map(({ node }) => (
        <Link to={`/confessions/${node.id}/`} key={node.id}>
          <Confession
            id={node.id}
            date={node.createdAt}
            text={node.confession.childMarkdownRemark.rawMarkdownBody}
          />
        </Link>
      ))}
      <PaginationLinks index={index} pageCount={pageCount} previousUrl={previousUrl} nextUrl={nextUrl} />
    </Layout>
  )
}

export default ConfessionsTemplate
